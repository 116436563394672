import cx from 'classix'

const Card: FCC<Props> = (props) => {
	const classList = cx(
		'drop-shadow-lg',
		'bg-white',
		'rounded-xl',
		props.small ? 'p-6' : 'p-12',
		props.className,
	)

	return (
		<div className={classList}>
			{props.children}
		</div>
	)
}

type Props = {
  className?: string
	small?: boolean
}

export default Card
