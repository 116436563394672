import type {
	InferGetStaticPropsType,
	PageConfig,
} from 'next'
import { PortableText } from '@portabletext/react'
import LinksSection from '~/client/modules/Home/LinksSection'
import QualityGuaranteePanel from '~/client/modules/SiteGlobals/QualityGuaranteePanel'
import MainLayout from '~/client/layouts/MainLayout'
import TwoColumnImgText from '~/client/components/TwoColumnImgText'
import Metadata from '~/client/components/Metadata'
import { getMetadata } from '~/server/services/SanityService/metadata'
import { getHomepage } from '~/server/services/SanityService/homePage'
import SmallBlurb from '~/client/components/SmallBlurb'
import StatsSection from '~/client/modules/Home/StatsSection'
import { getQualityPanel } from '~/server/services/SanityService/qualityPanel'
import { getSiteSettings } from '~/server/services/SanityService/siteSettings'

const IndexPage: NextPageWithLayout<Props> = (props) => {
	const {
		homePage,
	} = props

	return (
		<div className="py-24">
			<TwoColumnImgText
				headerText={homePage.firstPanel.headerText}
				image={homePage.firstPanel.image.url}
				imageAlt={homePage.firstPanel.image.alt}
				bodyText={(
					<PortableText
						value={homePage.firstPanel.content || []}
					/>
				)}
				buttons={homePage.firstPanel.buttons?.map((button) => {
					return {
						label: button.label,
						style: button.type,
						href: button.url,
					}
				})}
			/>
			<div className="px-6 lg:px-40 py-24 space-y-24">
				<div className="space-y-12">
					<SmallBlurb
						headerText={homePage?.smallBlurb?.headerText}
						content={(
							<PortableText
								value={homePage.smallBlurb.content || []}
							/>
						)}
					/>
					{homePage.statsSection?.stats?.length ? (
						<StatsSection stats={homePage.statsSection.stats} />
					) : null}
					{homePage.linksSection?.sections?.length ? (
						<LinksSection
							sections={homePage.linksSection.sections.map((section) => {
								return {
									headerText: section.headerText,
									button: {
										label: section.button.label,
										type: section.button.type,
										url: section.button.url,
									},
									content: (
										<PortableText value={section.content || []} />
									),
								}
							})}
						/>
					) : null}
				</div>
				<QualityGuaranteePanel
					image={props.qualityPanel?.image.url}
					imageAlt={props.qualityPanel?.image.alt}
					headerText={props.qualityPanel?.headerText}
					button={{
						label: props.qualityPanel?.button.label,
						linkStyle: props.qualityPanel?.button.type,
						href: props.qualityPanel?.button.url,
					}}
					content={(
						<PortableText value={props.qualityPanel?.content || []} />
					)}
				/>
			</div>
		</div>
	)
}

IndexPage.getLayout = (page, pageProps) => {
	const {
		homePage,
		metadata,
		siteSettings,
	} = pageProps

	const buttons = homePage.heroBanner.actionButtons?.map((button) => {
		return {
			label: button.label,
			style: button.type,
			href: button.url,
		}
	})

	return (
		<MainLayout
			gaId={siteSettings.gaId}
			heroImagePath={homePage.heroBanner.image.url}
			heroImageBlurHash={homePage?.heroBanner?.image?.blurHash}
			headerText={homePage.heroBanner.title}
			subtitleText={homePage.heroBanner.subTitle}
			buttons={buttons}
			footer={{
				email: siteSettings.companyEmailAddress,
				phoneNumber: siteSettings.companyPhoneNumber,
				iconImageUrl: siteSettings.certIcon.url,
				iconImageAlt: siteSettings.certIcon.alt,
			}}
		>
			<Metadata
				{...metadata}
			/>
			{page}
		</MainLayout>
	)
}

type Props = InferGetStaticPropsType<typeof getStaticProps>

export const getStaticProps = async () => {
	const [
		metadata,
		homePage,
		qualityPanel,
		siteSettings,
	] = await Promise.all([
		getMetadata(),
		getHomepage(),
		getQualityPanel(),
		getSiteSettings(),
	])

	return {
		props: {
			metadata,
			homePage,
			qualityPanel,
			siteSettings,
		},
	}
}

export const config: PageConfig = {
	unstable_JsPreload: false,
}

export default IndexPage
