import type { ComponentProps } from 'react'
import type Button from '~/client/components/Button'
import HeaderText from '~/client/components/HeaderText'
import LinkButton from '~/client/components/LinkButton'

const LinksSection: FC<Props> = (props) => {
	const {
		sections,
	} = props

	return (
		<div className="space-y-12">
			<div className="grid grid-cols-1 lg:grid-cols-3">
				{sections?.map((section) => {
					return (
						<div
							key={section.headerText}
							className="py-6 lg:py-14 lg:px-6 space-y-8 flex flex-col"
						>
							<div className="space-y-4">
								<HeaderText
									type="h3"
									className="font-bold"
								>
									{section.headerText}
								</HeaderText>
								<div className="prose-lg">
									{section.content}
								</div>
							</div>
							<div className="grow" />
							<LinkButton
								linkStyle={section.button.type}
								href={section.button.url}
								className="w-fit"
							>
								{section.button.label}
							</LinkButton>
						</div>
					)
				})}
			</div>
		</div>
	)
}

type Props = {
	sections?: {
		headerText: string
		content: JSX.Element | string | null
		button: {
			label: string
			type: ComponentProps<typeof Button>['buttonStyle']
			url: string
		}
	}[] | null
}

export default LinksSection
