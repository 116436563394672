import type { ComponentProps } from 'react'
import type Button from '~/client/components/Button'
import Image from 'next/image'
import HeaderText from '~/client/components/HeaderText'
import LinkButton from '~/client/components/LinkButton'

const TwoColumnImgText: FC<Props> = (props) => {
	const {
		headerText,
		bodyText,
		buttons = [],
		image,
		imageAlt,
	} = props

	return (
		<div className="grid grid-cols-1 lg:grid-cols-2 max-w-7xl mx-auto gap-12 lg:gap-24 h-full w-full px-6">
			<div className="flex justify-center items-center w-full">
				<div className="relative h-[32rem] lg:h-full w-full">
					<Image
						fill
						src={image}
						alt={imageAlt}
						className="object-contain"
						sizes={[
							'(max-width: 1024px) 100vw',
							'50vw',
						].join(',\n')}
					/>
				</div>
			</div>
			<div className="space-y-4">
				{headerText ? (
					<HeaderText
						type="h2"
						className="font-bold"
					>
						{headerText}
					</HeaderText>
				) : null}
				<div className="prose-lg">
					{bodyText}
				</div>
				<div className="pt-4">
					{buttons.map((button) => {
						return (
							<LinkButton
								key={button.label}
								linkStyle={button.style}
								href={button.href}
							>
								{button.label}
							</LinkButton>
						)
					})}
				</div>
			</div>
		</div>
	)
}

type Props = {
	image: string
	imageAlt: string
  headerText: string
  bodyText: JSX.Element
  buttons?: {
    label: string
    href: string
    style?: ComponentProps<typeof Button>['buttonStyle']
  }[]
}

export default TwoColumnImgText
