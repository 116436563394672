import HeaderText from '~/client/components/HeaderText'

const SmallBlurb: FC<Props> = (props) => {
	const {
		headerText,
		content,
	} = props

	return (
		<div className="max-w-2xl flex flex-col lg:items-center mx-auto space-y-5">
			{headerText ? (
				<HeaderText
					type="h2"
					className="font-bold"
				>
					{headerText}
				</HeaderText>
			) : null}
			{content ? (
				<div className="prose-lg lg:text-xl lg:items-center flex flex-col prose-ul:list-disc">
					{content}
				</div>
			) : null}
		</div>
	)
}

type Props = {
  headerText?: string | null
  content?: JSX.Element | null
}

export default SmallBlurb
